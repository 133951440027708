// Model https://github.com/marmelab/react-admin/blob/master/packages/ra-language-french/src/index.ts

module.exports = {
  app: {
    action: {
      create: "Add",
      search: "Search",
      newSearch: "Start a new search",
      contactPlace: "Contact the place",
      successAddBookmark: "Added to your bookmarks",
      successRemoveBookmark: "Removed from your bookmarks",
      successMessageSent: "Votre message a bien été envoyé",
      publish: "publish",
      unpublish: "unpublish",
      published: "The resource has been published",
      unpublished: "The resource has been unpublished",
      userCreation: "User creation in progress...",
      userCreated: "User created",
      close: "Close",
      send: "Send",
      listView: "list view",
      mapView: "map view",
      calendarView: "calendar view",
      filters: "filters",
      removeFilters: "remove all filters",
      showFilters: "Show all filters",
      help: "Help",
      contactPathOrganizer: "Contact the organizers of this Community of Practice",
      organization: {
        contact: "Contact the organization",
        create: "Add an organization",
        myOrganizations: "My organizations",
      },
      person: {
        contact: "Contact",
      },
      event: {
        about: "About the event",
        event: "Events",
        contact: "Contact the organizers",
        create: "Add an event",
        myEvents: "List",
        register: "I register",
      },
      place: {
        myPlaces: "My places",
        create: "Add a place",
      },
      offerAndNeed: {
        contact: "Contact the writer",
        mine: "My announcements",
        create: "Add an announcement",
      },
      alert: {
        mine: "My alerts",
        create: "Add an alert",
      }
    },
    page: {
      home: "Welcome to the E-community Research Platform !",
    },
    card: {
      organization: {
        description: "Description",
        hasRegion: "Region",
        organizationHostedIn: "Where are we",
        affiliates: "Has contributor",
        supports: "Contributor of",
        organizes: "Upcoming activities",
        onlyFutureEvents: "Display only upcoming activities",
        partnerOf: "Partner of"
      },
      person: {
        hasRegion: "Region",
        hasType: "Person Type",
        proposes: "Host of",
        affiliatedBy: "Member of",
        supports: "Contributor of",
        organizes: "Organizer of",
        mentorOn: "Mentor on",
      },
      event: {
        duration: "Duration",
        onlyFutureEvents: "Display only upcoming events",
        localisation: "Localisation",
      },
      course: {
        onlyFutureCourse: "Only display upcoming trips",
      },
      registration: {
        bookingPeriod: "Booking date: from ",
        from: "from",
        to: " to ",
        bookedOn: " Booked on: "
      },
      place: "Place",
      location: "Location",
      similar: "similar",
      duration: {
        months: "months",
        days: "days",
        hours: "hours",
        minutes: "minutes",
        minute: "minute",
      }
    },
    block: {
      search: "Follow the E-community Research Platform",
    },
    input: {
      courseType: "Course type",
      sector: "Activity sector",
      topic: "Keyword",
      region: "Region",
      targetAudience: "Target Audience",
      listView: 'List View',
      dates: 'Dates',
      organizer: 'Organizer',
      facilitator: 'Facilitator',
      linkedPath: 'Linked Community of Practice',
      placeType: "Place type",
      offerAndNeedType: "Announcement type",
      skills: "Skills",
      capacityFrom: "From",
      capacityTo: "to",
      miminumPerson: "minimum people",
      maximumPerson: "maximum people",
      externalForm: "Link to external registration system",
      chooseDefaultForm: "Choose the default form",
      defaultForm: "Default form",
      chooseRegistrationType: "Choose a registration option",
      chooseJotform: "Choose a JotForm",
      organization: {
        label: "Organization Name",
        hasType: "What type of organization?",
        comment: "Could you describe it in one sentence?",
        description: "Feel free to describe it in more detail here",
        depictedBy: "Add a logo!",
        hasSector: "In which sectors does it operate?",
        hasTopic: "What keywords would you use to characterize it?",
        hasFinality: "What goals are you pursuing?",
        intentions: "What are your intentions in coming to the E-community Research Platform?",
        practicalConditions: "Can you accommodate travelers? If yes, under what conditions?",
        maximumCapacity: "How many travelers maximum?",
        hasCourseType: "In what travel modes can you accommodate travelers?",
        produces: "What skills can you offer?",
        partnerOf: "Do you have partners on the E-community Research Platform?",
        inspiredBy: "Organizations that inspire you?",
        affiliates: "Members of your organization on the E-community Research Platform?",
        supports: "Are you joining a Community of Practice?",
        organizationHostedIn: "In which place(s) are you present?",
        capacityUnit: "persons",
      },
      event: {
        type: "Event type",
        hasTargetAudience: "Target Audience",
        listView: 'List View',
        duplicate: "Duplicate an existing event?",
      },
      person: {
        firstName: "Your first name",
        lastName: "Your last name",
        email: "Your email",
        message: "Message",
        alternativeLabel: "Your username",
        comment: "Who are you in one sentence?",
        homePage: "A link about you?",
        description: "Feel free to describe yourself in more detail!",
        intentions: "Your intentions in coming to the E-community Research Platform?",
        depictedBy: "Your photo! (It's important for the platform's friendliness)",
        hasSector: "Are you interested in specific activity sectors?",
        hasTopic: "What are your areas of interest?",
        offers: "What are your current skills?",
        aims: "What skills are you looking for?",
        hasFinality: "What goals are you pursuing?",
        phone: "Your phone number?",
        website: "Your website?",
        hasLocation: "Where do you live?",
        affiliatedBy: "Member of one (or more) organizations?",
        links: 'Links',
        inspiredBy: "Are there individuals who inspire you?"
      }
    },
    helper: {
      choose: "Choose...",
      organization: {
        email: "Not visible on the platform",
        phone: "Not visible on the platform",
        publicPhone: "Public number displayed on the page",
      },
      person: {
        email: "Not visible on the platform",
        phone: "Not visible on the platform",
        publicPhone: "Public number displayed on the page",
      },
      nonVisible: "Not visible on the platform",
      publicPhone: "Publicly displayed phone number on the page",
      publicLink: "Link publicly displayed on the page",
      directRegistration: "If not, interested people will need to contact you directly via a contact form beforehand",
      practicalConditions: "Specify if equipment is needed, registration procedures, accommodation, meals...",
      accessibility: "Specify the event's accessibility for people with disabilities",
      full: "Check this box if the event is full",
      financialSupport: "If eligible, specify the types of funding (CPF, Qualiopi...)",
      connectToAddBookmark: "Connect to add a page to your bookmarks",
      userCreation: "If you create a user of type Actor, they will automatically receive an invitation at their email address.",
    },
    message: {
      welcome: "An open, peer-to-peer platform to serve researchers, practitioners, and adult educators from ecovillages and academia as well as policy makers on all levels in order to make the knowledge gained within and through ecovillages more accessible both for research and policy decisions on sustainable lifestyles and living environments. ",
      login: "Welcome to the E-community Research Platform! Creating an account on the platform will allow you to provide information about your profile: who you are, what you're involved in, what you're looking for, and what you have to offer. It will also enable you to save your favorites (actors, places, events, journeys) and join the E-community Research Platform community! By logging in, you confirm your adherence to the E-community Research Platform Charter.", //TODO @Tonfa add <Link to='/Page/charte-des-chemins-de-la-transition/show' target='_blank'> to the text as hyperlink 
      organization: {
        involvedActivities: "This organization is involved in multiple activities. Click to learn more and/or participate.",
      },
      errorAuth: "Please log in to access the contact form",
      errorRegistration: "You must be logged in to register",
      moreEvents: "This place offers multiple events. Click on it to learn more and/or participate.",
      results: "result(s)",
      noResult: "No result found",
      topBarMessage: "The place to collaborate on ecovillage research",
      topBarButton: "Support the platform with a donation",
      betaVersion: "Beta version",
      reminderToFill: "Don't forget to fill in the",
      reminderSecondTab: "second tab",
      reminderEndText: "of the form, that's where we ask the most interesting questions!",
      noSavedBookmarks: "No saved favorites",
      error: "An issue occurred",
      colloquiumsIntro: "Inspired by a previous series of colloquiums organized by the GEN Research Group between 2020 and 2022, the ReGEN4all colloquiums seek to provide a space for interaction and constructive dialogue among community-led initiatives, universities and academics. The ReGEN4all colloquiums were, however, developed in a new and innovative format, in which not only researchers but also practitioners were invited to present and reflect on relevant topics. The goal was to connect research and practice to produce actionable knowledge that is based on the concrete experiences of change-makers on the ground, while at the same time being supported by scientific data. The colloquium series is open to all interested people and free of charge in order to make knowledge on ecovillages more accessible to broader society.",
      colloquiumsIntro2: "The ReGEN4all colloquium series consisted of six events on the topics described below. Here you can find their video recordings, information on the presenters, and additional materials. If you have any suggestions of topics you would like to see being discussed on the colloquium series or interesting people you would like us to invite, feel free to write your suggestions in our Forum.",
      erasmusPlus: "Funded by the European Union. Views and opinions expressed are however those of the author(s) only and do not necessarily reflect those of the European Union or the European Education and Culture Executive Agency (EACEA). Neither the European Union nor EACEA can be held responsible for them.",
      welcomeContent: {
        title: "Connect, collaborate,",
        subTitle: "co-create...",
        text: "The Ecommunity Research Platform offers a space where researchers, educators and practitioners can expand their horizons, share ressources and shape a growing Community of Practice.",
      },
      home: {
        places: "Places",
        toVisit: "to visit",
        discoverPlaces: "Match with places involved in transitions, activities that are practiced there, meet the actors and actresses who are making tomorrow's world!",
        seeAllPlaces: "See all places here",
        agenda: "Agenda",
        events: "Events",
        discoverEvents: "Attend some of the events and meet others interested in ecovillage research - the first step for inspiration and new collaborations",
        seeAllEvents: "See all events",
        paths: "Communities of Practice",
        toDiscover: "To join",
        dicoverPaths: "We meet and greet regularly, you can join more than one community of practice if you like. Within the communities of practice we exchange about our experience, develop project ideas together, help each other with our research projects and support policy papers if needed.",
        seeAllPaths: "See all Communities of Practice",
        courses: "Our travels",
        themesAndLocation: "Themes & locations",
        discoverCourses: "Discover preconceived journeys, meeting several transition initiatives, on a given theme and/or geographical sector!",
        seeAllCourses: "See all trips",
        followUs:"Follow us",
      },
      coursesTypes: {
        title: "The largest collection of ecovillage related research and resources",
        subTitle: "Tailored to your interests and needs",
        learnByTravelling: "As a researcher, ecovillager or educator",
        mode: "you can",
        discovery: "Reach out",
        discoverySubtext: "Connect with likeminded people, to exchange experience, knowledge, findings, ideas etc.",
        discoverySubtextAbout: "I'm curious about ecovillage research and take my first step to connect and discover the field.",
        learning: "Collaboration",
        learningSubtext: "Join our Communities of Practice. They exist on national, regional and international scale.",
        learningSubtextAbout: "A Community of Practice (CoP) interests me, I want to know more. I can take advantage of the colloqiums and events that activates the CoP.",
        immersion: "Needs & Offers",
        immersionSubtext: "Match with others and create synergies for a more successful research",
        immersionSubtextAbout: "I want to find ecovillages and ecocommunities that fit my research work, to send questionnaires, conduct interview, or in-field research",
        mentoring: "Access ecovillage research",
        mentoringSubtext: "Visit the library and its collection of literature about ecovillage research ",
        mentoringSubtextAbout: "I'm ready to commit to training in a specific practice. You'll be deeply immersed in a location and complete theoretical modules for validation of your skills.",
        courseDiscovery: "Discovery Journey",
        courseLearning: "Learning Journey",
        courseImmersion: "Immersive Journey",
        courseMentoring: "Mentorship",
      },
      partners: {
        withTheSupport: "With the support of",
        financialPartners: "the project team",
        businessPartners: "our funders",
      },
      traveler: {
        joinCommunityText: "Do you need support in your research? Partners to interview? A specific place to do research on? Or do you have an important question that arose in your community and could be answeredy by researchers?",
        joinCommunityButton: "Add your research needs or offers here",
      },
      goals: {
        title: "The Goal",
        subTitle: "Empowering Ecovillage research",
        subText: "The Ecommunity Research Platform connects and links different actors around ecovillages, education, science and research and can inform policy making and advocacy efforts. It will make the knowledge gained within and through ecovillages accessible both for research and policy decisions on sustainable lifestyles and living environments.",
        goal1: "Connecting researchers, educators and practitioners",
        text1goal1: "Creating a community of practice environment for people to meet, share and collaborate on ecovillage research.",
        text2goal1: "Gathering ecovillage research and making it accessible and visible - to be used.",
        text3goal1: "Setting up guidelines for research in ecovillages - developing a Code of Conduct.",
        text4goal1: "Accelerating projects through assistance provided by travelers.",
        goal2: "Researching, sharing, collaborating, educating",
        text1goal2: "We offer you a place to find likeminded people and research in community",
        text2goal2: "Exchange forums and communities of practice",
        text3goal2: "Research ideas from the field itself and places where you can go for research",
        text4goal2: "Enabling the discovery and learning of transition professions through contact with passionate and committed actors.",
        goal3: "Researcher and educators as pollinators of knowledge and practices also for policy work",
        text1goal3: "Contributing to a wider knowledge about intentional communities and ecovilages",
        text2goal3: "Inspiring activists to start a new ecological project",
        text3goal3: "Stimulating policy makers to contribute to a great environment for ecological projects of all kinds and sustainable living conditions",
        text4goal3: "Amplifying socio-ecological transition and transformation by spreading the word and making the knowledge available for everybody",
        text5goal3: "Bringing local solutions on meta levels"
      },
      categories: {
        title: "Diverse categories",
        subTitle: "to explore",
      },
      footer: {
        codeOfConduct: "E-community Research Platform Charter",
        legalNotice: "Legal Notice",
        dataPolicy: "Data Management Policy",
        join: "Join the community",
        joinSubText1: "If you are interested, join the conversation in our Community of Practice Forum",
        joinSubText2: "It is an opportunity to exchange and discuss with researchers and practitionners",
        joinButton: "Join the COP Forum",
        usefulLinks: "Useful Links",
        about: "About",
        forum: "Our Forum",
        website: "Our Website",
      },
    },
    tab: {
      path: {
        about: "About this Community of Practice",
        links: "Links",
        place: "Places",
        event: "Events",
        course: "Journeys",
      },
      organization: {
        about: "About this organization",
        links: "Organization links",
        contact: "Contact",
        visibility: "Visibility",
        skills: "Skills",
        accommodation: "Accommodation",
        activities: "Activities",
        inspiredBy: "Inspired by",
      },
      person: {
        about: "About",
        affiliates: "Connected with you",
        links: "Linked with the organization",
        contact: "Contact",
        visibility: "Visibility",
        skills: "Skills",
        inspiredBy: "Inspired by",
      },
      event: {
        about: "About the event",
        pathway: "Journey Program",
        location: "Location",
        title: "Our journeys",
        subTitle: "For more inspiration",
        linkText: "See all journeys",
        learningObjectives: "Learning Objectives",
        practicalConditions: "Practical Information",
      },
      course: {
        about: "About the journey",
        pathway: "Journey program",
        location: "Location",
        title: "Our journeys",
        subTitle: "For more inspiration",
        linkText: "View all journeys",
      },
      place: {
        about: "About the place",
        link: "Related to the place",
        contact: "Contact",
        title: "More places",
        subTitle: "For more inspiration",
        linkText: "See all places"
      },
      offerAndNeed: {
        description: "Description",
        contact: "Contact",
        visibility: "Visibility"
      },
      economicalConditions: 'Economical Conditions',
      accommodation: 'Accommodation',
      contact: 'Contact',
      visibility: 'Visibility',
      skills: 'Skills',
      inspiredBy: 'Inspired by',
      links: 'Links',
      description: 'Description',
      dates: 'Dates',
      organizer: 'Organizer',
      facilitator: 'Facilitator',
      linkedPath: 'Linked Community of Practice',
    },
    notification: {
      full: "This event is full",
      partOf: "This event is part of the journey",
      financialSupport: "Discover various funding mechanisms",
    },
    validation: {},
    menu: {
      bookmarks: "My bookmarks",
      places: "My Places",
      events: "My Events",
      organizations: "My Organizations",
      reservations: "My Bookings",
      login: "Login",
      signup: "Sign Up",
      profile: "My Profile",
      offerAndNeed: "Announcements",
      colloquiums: "The ReGEN4all Colloquium Series"
    },
    nav: {
      home: "Home",
      path: "Communities of Practice",
      place: "Places",
      event: "Events",
      course: "Courses",
      offerAndNeed: "Needs & Offers",
      library: "E-community Library",
      codeOfConduct: "Code of Conduct",
      colloquiums: "Colloquiums"
    },
    bookmark: {
      title: "My bookmarks",
      path: "Community of Practice",
      place: "Places",
      event: "events",
      course: "courses",
      offerAndNeed: "Announcement board",
      pathHeadComment: "To compose my trip",
      placeHeadComment: "To compose my trip",
      eventHeadComment: "To compose my trip",
      courseHeadComment: "To compose my trip",
      offerAndNeedHeadComment: "To compose my trip",
    }
  },
};
